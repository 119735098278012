import React from 'react';
import { Card, Image, Text, Badge, Button, Group, useMantineTheme,Tabs, Tab } from '@mantine/core';
import { FiPhone,FiHome,FiBriefcase,FiMail } from 'react-icons/fi';
import {GoInfo,GoQuestion, GoNote} from 'react-icons/go';
import { useMediaQuery } from '@mantine/hooks';
import logo from './assets/img/pf_logo.jpg'
import t1 from './assets/img/t1.jpg';
import t2 from './assets/img/t2.jpg';
import t3 from './assets/img/t3.jpg';

export default function Banner(){

    const match = useMediaQuery('(min-width:900px)')
    const theme = useMantineTheme();
    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];


    return (
        <div style={{width:"100%",height:"100%", backgroundColor:"#0275d8"}}>
            <div style={{height:"100%",width:"80%",margin:'auto',}}>
                <div style={{overflow:"hidden", height:"auto", maxHeight:"700px", width: match ? "240px" : "305px",margin:"auto",position: "relative"}}>
                    <Image
                    radius={match ? "xs" : 0}
                    src={logo}
                    //position={"center"}
                    style={{margin:"auto",width:"100%",height:"100%", display:"block",bottom:0}}
                    >
                    </Image>
                </div>
                <Card shadow="xl" 
             
                style={{borderWidth:50}}
                >
                    <Tabs grow>
                    <Tab label={match ? 'About Us:' : ''} icon={<GoInfo/> }>
                        <Card shadow="sm" style={{margin:"auto",height:"900px"}}>    
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                <Text size="lg" weight={700}>About Us:</Text>
                            </Group>
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                <Text size="md" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                    Perfect Gas is a (Pty) Ltd Company that is in the business of selling and distribution of Propane and Liquid petroleum gas to the domestic, commercial, and industrial market. 
                                    The company is situated on the farm Saltberry Plain, approx. 6 km from the Sasolburg CBD, Free State. The company has grown tremendously over the last few years and is in possession of 6 distribution vehicles to supply our current growing customer base. 
                                </Text>
                            </Group>
                            {/* 
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }} align="left">
                                <Text size="lg" weight={700}>Operational Planning:</Text>
                            </Group>
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 1.5,allign: "left"}} align="left">
                                    Currently the company is in possession of 6 light distribution vehicles to supply the
                                    current customer base. The light delivery vehicles are used to capacity.
                                </Text>
                            </Group> */}
                        </Card>
             
                        <div style={{display:'flex',flexWrap:"wrap", marginTop:"-400px"}}>
                            <div style={{flex:33.33, padding: "5px"}}>
                                    <Card>
                                        <Image
                                        radius="xs"
                                        src={t1}
                                        // position={"center"}
                                        width={300}
                                        style={{ width: 240, margin: 'auto'}}
                                        >
                                        </Image>
                                    </Card>
                            </div>
                            <div style={{flex:33.33, padding: "5px"}}>
                                <Card>
                                    <Image
                                    radius="xs"
                                    src={t2}
                                    // position={"center"}
                                    width={300}
                                    style={{ width: 240, margin: match ? 'auto': '0'}}
                                    >
                                    </Image>
                                </Card>
                            </div>
                            <div style={{flex:33.33, padding: "5px"}}>
                                <Card>
                                    <Image
                                    radius="xs"
                                    src={t3}
                                   // position={"center"}
                                    width={300}
                                    style={{ width: 240, margin: 'auto'}}
                                    >
                                    </Image>
                                </Card>
                            </div>
                        </div>
                    </Tab>
                    <Tab label={match ? 'Mission' : ''} icon={<GoQuestion/>}>
                        <Card shadow="sm" style={{height:"900px"}}>
                                
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text size="lg" weight={700}>Mission Statement:</Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 1.5 }} align="left">
                                Labohlano trading is committed to the sale and distribution of propane and LP gas at a competitive market price.  
                                We service both the established and rural communities and promote social up-liftment,
                                 while upholding the highest standards of safety and quality.
                                </Text>
                                </Group>
                            </Card>
                    </Tab>
                    {/* <Tab label="Market Plan" icon={<FiBriefcase/>}>
                    <Card shadow="sm" padding="lg" style={{height:"900px"}}>
                            
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                <Text weight={700}>Pricing:</Text>
                            </Group>
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                            <Text size="md" style={{width:"700px", color: secondaryColor, lineHeight: 1.5 }} align="left">
                                
                            The Gas market is driven primarily by a combination of supply and demand as well as
                            the oil price. The market has also been identified as seasonal which is tied to the
                            physical seasons (i.e. summer and winter). Pricing is also subject to the pricing of the
                            supplier as Perfect Gas is a secondary distributor.
                            </Text>
                            </Group>

                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                <Text weight={700}>Demand Management:</Text>
                            </Group>
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                            <Text size="md" style={{width:"700px", color: secondaryColor, lineHeight: 1.5 }} align="left">
                                Perfect Gas is currently a supplier to large fabrication engineering
                                works that rely on Perfect Gas’s product for a number of applications,
                                such as cutting, heating and heat treatment.
                            </Text>
                            </Group>

                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                <Text weight={700}>Restaurants:</Text>
                            </Group>
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                            <Text size="md" style={{width:"700px", color: secondaryColor, lineHeight: 1.5 }} align="left">
                                The commercial and public food industry relies heavily on Gas to
                                facilitate cooking and heating during the colder months. It is Perfect
                                Gas’s plans to become a major supplier in this industry
                            </Text>
                            </Group>

                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                <Text weight={700}>Filling Facilities:</Text>
                            </Group>
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                            <Text size="md" style={{width:"700px", color: secondaryColor, lineHeight: 1.5 }} align="left">
                                Perfect Gas is currently a supplier to many filling facilities. Perfect Gas
                                has begun an initiative to empower black entrepreneurs to own and
                                operate their own filling facility in underprivileged communities. In the
                                process empowering the local community and supplying the neglected
                                communities with vital gas which they use for everyday survival.
                            </Text>
                            </Group>

                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }} align="left">
                                <Text weight={700}>Engineering Works:</Text>
                            </Group>
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                            <Text size="md" style={{width:"700px", color: secondaryColor, lineHeight: 1.5}} align="left">
                                Perfect Gas is currently a supplier to large fabrication engineering
                                works that rely on Perfect Gas’s product for a number of applications,
                                such as cutting, heating and heat treatment.
                            </Text>

                            </Group>
                        </Card>
                    </Tab> */}
                     <Tab label={match ? 'Policy Statement' : ''} icon={<GoNote/>}>
                            <Card shadow="sm" style={{height:"900px"}}>
                                
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={700}>POLICY STATEMENT:</Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 2, marginTop: theme.spacing.xs }}>
                                <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 2 }} align="left">
                                We at Perfect Gas (Pty) Ltd will continuously strive to:</Text></Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                <Text  size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 2 }} align="left">
                                   <ul>
                                    <li>Provide a clean, safe, and healthy working environment.</li>
                                     <li>
                                        It will be the duty and responsibility of every employee to take appropriate care of 
                                        their own SHEQ and those who may be affected by their action.  They must report all potential hazards to the employer.
                                     </li>
                                     <li>
                                         Provide training for all employees to enable and encourage them to perform their jobs efficiently with special emphasis on their commitment to safety, health, environment, and quality.
                                     </li>
                                     <li>
                                         Use the SQAS (safety, Health, Environment and quality) system as a basis for our own systems, which will include behavior-based safety.
                                     </li>
                                     <li>
                                         Create systems for effective environmental management.
                                     </li>
                                     <li>
                                         To meet our customer’s expectations and always provide exemplary service.
                                     </li>
                                    </ul>   
                                </Text>
                                </Group>
                            </Card>
                    </Tab>
                    <Tab label={match ? 'Management' : ''} icon={<FiBriefcase/>}>
                        <Card shadow="sm"  style={{height:"900px"}}>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={700}>Management Team:</Text>
                                </Group>
                                <Card shadow="sm" >
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Director of Operation	             :  Allan Peeters & Dawie Cloete
                                        </Text>
                                    </Group>
                                </Card>
                                <Card shadow="sm">
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Chief Executive Officer	             :  Elaine Cloete
                                        </Text>
                                    </Group>
                                </Card>
                                <Card shadow="sm" >
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Financial Director : Louise Peeters
                                        </Text>
                                    </Group>
                                </Card>
                                <Card shadow="sm">
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Director of Sales and Marketing	: Willem Carstens
                                        </Text>
                                    </Group>
                                </Card>
                            </Card>
                        </Tab>
                    <Tab label={match ? 'Contact' : ''} icon={<FiPhone/>}>
                        <Card shadow="sm" style={{height:"900px"}}>
                            <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                <Text size="lg" weight={700}>Contact Details <FiMail/></Text>
                            </Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                    <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                    Office:
                                    <br/>
                                    -<FiPhone/>	<a href="tel:+016-9766529">016 976 6529</a> 
                                    <br/>
                                    -<FiPhone/>   <a href="tel:+016-9764618">016 976 4618</a> 
                                    </Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                    <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                        Office-Cell:
                                        <br/>
                                        -<FiPhone/>	<a href="tel:+27760859668">076 085 9668</a> 
                                    </Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                    <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                        Email:
                                        <br/>
                                        <FiMail/><a href="mailto:orders@perfectgas.co.za">orders@perfectgas.co.za</a>
                                    </Text>
                                </Group>
                        </Card>
                    </Tab>
                    </Tabs>
                    
                </Card>
                </div>
            </div>
    )
}